.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.mapboxgl-popup-tip {
  display: none;
}
